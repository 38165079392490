import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PortableText } from "@portabletext/react";

import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import SanityImage from "gatsby-plugin-sanity-image";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import "./BlogAuthorPage.scss";
import BlogPostList from "../../components/blog/BlogPostList";
import { sortBlogPosts } from "./util/sortBlogPosts";
import SectionTitle from "../../components/SectionTitle";
import BreadcrumbBanner from "../../components/BreadcrumbBanner";
import Separator from "../../components/Separator";
const AboutAuthor = ({ author, column, teamStyle }) => {
  return (
    <div className="row">
      <div className={`${column}`}>
        <div className={`blog-author-profile ${teamStyle}`}>
          <div className="row align-items-center row--20">
            <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
              <div className="content mt_sm--40">
                <div className="client-info">
                  <h4 className="title">Author Profile</h4>
                  {/*<h6 className="subtitle">Author Profile</h6>*/}
                </div>
                <div className="description">
                  <PortableText value={author._rawAbout}></PortableText>
                </div>
              </div>
            </div>
            <div className="order-1 order-md-2 col-lg-4 col-md-4">
              <div className="thumbnail">
                <SanityImage
                  {...author.photo}
                  alt={author.name}
                  className="w-100 "
                  width={400}
                  height={400}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const BlogAuthorPage = ({ pageContext, data }) => {
  const { author } = pageContext;
  const posts = sortBlogPosts(author.posts);
  console.log(author);
  return (
    <>
      <SEO title={`${author.name}`} />
      <Layout>
        <BreadcrumbBanner
          title={`${author.name}`}
          rootUrl={"/articles"}
          parentUrl={"News and views"}
          currentUrl={author.name}
        />
        <div className="rn-blog-area rn-section-gap">
          <div className="container">
            <AboutAuthor
              column="col-lg-12 mt--30"
              teamStyle=""
              author={author}
            />
          </div>
        </div>
        <Separator />
        <div className="rn-blog-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Articles from this author"
                  description=""
                />
              </div>
            </div>
            <div className="row mt_dec--30">
              <BlogPostList
                column="col-lg-4 col-md-6 col-12 mt--30"
                StyleVarProp="box-card-style-default"
                posts={posts}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default BlogAuthorPage;
